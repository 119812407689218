<template>
  <div class="header__wrp">
    <header>
      <div class="menu" :style="{ background: !darkMode ? 'url(/img/mobile-menu-dark.svg)' : 'url(/img/mobile-menu.svg)'}" @click="setSpecialistMobileMenu">

      </div>
        <router-link to="#" class="logo"></router-link>
      <div class="right">
        <div class="wrapp-countCard" @click="goToCheckout">
          <img :style="{'filter': !darkMode ? 'invert(1)' : 'unset'}" class="icon-card" src="/img/card.svg" alt="">
          <div v-show="totalCnt" class="conuter">{{ totalCnt }}</div>
        </div>
        <div @click="onModeChenge()" class="mode">
        </div>
        <SelectorLang class="selector_lang" :color="headerColor"/>
      </div>
    </header>
  </div>
</template>

<script>
import LangRu from '@/lang/components/header/ru' 
import LangRo from '@/lang/components/header/ro' 
import LangEng from '@/lang/components/header/eng' 
import SelectorLang from '@/components/SelectorLang'
import { getProductClientPrice, getCurrency } from '@/utils/helpers';

import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    SelectorLang
  },
  mixins:[LangRu, LangRo, LangEng],
  data:()=>({
    lang: ''
  }),
  mounted(){
    this.scrollHeader();
    this.lang = this.langStorage;
  },
  watch:{
    lang(){
      this.setLang(this.lang);
    }
  },
  methods: {
    scrollHeader(){
      const body = document.body
      const scrollUp = "scroll-up"
      const scrollDown = "scroll-down"
      let lastScroll = 0;
      window.addEventListener("scroll", () =>{
        const currentScroll = window.pageYOffset;
        if(currentScroll <= 0){
          body.classList.remove(scrollUp);
          return
        }
        if(currentScroll > lastScroll && !body.classList.contains(scrollDown)){
          body.classList.remove(scrollUp);
          body.classList.add(scrollDown)
        }
        else if(currentScroll < lastScroll && body.classList.contains(scrollDown)){
          body.classList.remove(scrollDown)
          body.classList.add(scrollUp)
        }
        lastScroll = currentScroll
      })
    },
    onModeChenge(){
      this.setDarkMode(!this.darkMode);
    },
   ...mapActions(['setSpecialistMobileMenu', 'setDarkMode']),
   ...mapActions('languages', ['setLang']),
   ...mapActions('googleEvents', ['sendBeginCheckoutEvent']),
   goToCheckout(){
    this.sendBeginCheckoutEvent({
      items: this.getProductStore.map(p => ({
        title: p.title,
        price: p[getProductClientPrice(this.localSpecialist.city)],
        quantity: p.cnt
      })),
      currency: getCurrency(this.localSpecialist.city)
    })
    this.$router.push({name: 'payment'});
   }
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang',
      isLogin: 'user/isLogin',
      darkMode: 'darkMode',
      totalCnt: 'checkout/totalCnt',
      checkCountry: 'user/checkCountry',
      getProductStore: 'checkout/products',
      localSpecialist: 'specialists/localSpecialist'
    }),
    selectLang() {
      if(this.langStorage == 'ro'){
        return this.langRo;
      }
      else if(this.langStorage == 'ru'){
        return this.langRu;
      }
      return this.langEng;
    },
    headerColor(){
      return this.darkMode ? '#2B2A29' : 'white';
    }
  }
}
</script>

<style lang="scss" scoped>
@media(max-width: 767px){
  .mode{
    margin-right: 1em !important;
  }
}
.scroll-down .header__wrp{
  transform: translate3d(0, -100%, 0)
}
.scroll-up .header__wrp{
  transform: none;
}
.header__wrp{
  position: fixed;
  top: 0;
  z-index: 100;
  transition: transform .3s ease;
}
  .header__wrp {
    padding: 0 20px;

  }
  .login.aush {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .wrapp-countCard {
    cursor: pointer;
    position: relative;
    margin-right: 25px;
    @media (max-width:430px) {
      margin-right: 15px;
    }
    .icon-card {
      width: 20px;
    }
    .conuter {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      font-weight: 800;
      color: white;
      left: 12px;
      font-size: 10px;
      background-color: #DC3E6C;
      border-radius: 50% ;
      width: 14px;
      height: 14px;
    }
  }
  .shop-lock {
    display: block;;
    position: relative;

    &:hover::after {
      content: attr(data-title);
      position: absolute;
      background-color: #EFEEFD;
      width: 145px;
      padding: 8px;
      border-radius: 12px;
      font-size: 14px;
      line-height: normal;
      color: black;
      top: 80%;
      left: 0;
      z-index: 1;
    }
  }
  @media(max-width: 1024px) and (min-width: 430px){

    header .menu {
      display: block;
      height: 24px;
      width: 24px;
    }
  }
</style>